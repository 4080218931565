<template>
    <div class="pageTempCls">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "pageTemp"
    }
</script>

<style scoped>
    .pageTempCls {
        display: flex;
        justify-content: left;
        height: 100%;
        background: rgba(255,255,255,0.95);
        border-radius: 10px;
        overflow: auto;
    }
</style>