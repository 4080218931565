const state = {
    TRAIN_STATE: ''
}

const mutations = {
    TRAIN_MUT: (state, data)  =>{
        state.TRAIN_STATE = data
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}