import Vue from 'vue'
Vue.prototype.$vue = Vue

import App from './App.vue'
Vue.config.productionTip = false

// 引入vuex
import store from './store'

const curEnv = process.env.NODE_ENV
const comEnv = require('../env/comEnv') // 引入公用的环境变量
let curEnvParam = {}  // 当前模式下，对应存在的变量
if(curEnv == 'yt001') {
    curEnvParam = require('../env/yt001')
} else if(curEnv == 'yt002') {
    curEnvParam = require('../env/yt001')
}
const globalEnv = Object.assign(comEnv,curEnvParam) // 变量合并
Vue.prototype.$globalEnv = globalEnv;    //将全局变量模块挂载到Vue.prototype中

// 引入路由表
import router from './router/router'

// 定义全局公用方法
import com from './utils/com.js'
Vue.prototype.$com = com

// 定义全局常量
import constVal from './utils/constant'
Vue.prototype.$constVal = constVal

// 定义全局过滤器
import * as filters from './utils/filter'
Object.keys(filters).forEach(key =>{
    Vue.filter(key, filters[key])
})

// 定义全局表单验证方法
import * as validates from './utils/validate'
Object.keys(validates).forEach(validate =>{
    Vue.prototype.$validate= validate
})

// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 页面样式模板全局注册
import template from '@/components/template/pageTemp'
Vue.component('pageTemplate', template)



new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
