/*
 * Created by luoxuemei on 2019/09/02.
 */

// 过滤器方法，key为需要过滤的值，fieldGetter用于查询vuex中对应的字典列表
export function filterFieldFun(key, fieldGetter) {
  if (key === '' || key === null || key === 'undefined' || typeof(key) == 'undefined' ) {
    return key
  }
  const paramAry = store.getters[fieldGetter]
  if(typeof (paramAry) == 'undefined'){
    return key
  }
  let rspVal = ''
  if (key === '' || key === null ) {
    rspVal = key
  } else {
    for (let i = 0; i < paramAry.length; i++) {
      if (paramAry[i].value == key) {
        rspVal = paramAry[i].label
        break
      } else {
        rspVal = key
      }
    }
  }
  return rspVal
}

// 过滤器方法，key为需要过滤的值，fieldGetter用于查询vuex中对应的字典列表,返回用逗号隔开的字符串
// value ,key 分别表示list遍历对象里面需要保存和展示的key,value值
export function filterFieldListFun(keyAry, fieldGetter, valueObj='value', labelObj='label') {
  if(keyAry && keyAry != null && keyAry != undefined) {
    if(typeof keyAry==='string'){
      if(keyAry){
        keyAry=keyAry.split(',')
      }else{
        keyAry=[]
      }
    }
    let rspVal = []
    const paramAry = store.getters[fieldGetter]
    for(let i =0; i<keyAry.length; i++){
      const key = keyAry[i]
      if (key != '' && key != null && key != 'undefined' && typeof(key) != 'undefined' ) {
        if(typeof (paramAry) == 'undefined'){
          rspVal = key
        }
        if (key != '' || key != null ) {
          for (let i = 0; i < paramAry.length; i++) {
            if (paramAry[i][valueObj] == key) {
              rspVal.push(paramAry[i][labelObj])
            }
          }
        }
      }
    }
    return rspVal.join(',');
  } else {
    return keyAry
  }
}

// 过滤器方法，key为需要过滤的值，dataObj为[{},{}]格式的数据源
export function filterKeyValFun(key,dataObj, valueObj='value', labelObj='label') {  //
  if (key === '' || key === null) {
    return key;
  }
  const paramAry = dataObj;
  let rspVal = '';
  if (key === '' || key === null ) {
    rspVal = key;
  } else {
    for (let i = 0; i < paramAry.length; i++) {
      if (paramAry[i][valueObj] == key) {
        rspVal = paramAry[i][labelObj];
        break;
      } else {
        rspVal = '未知';
      }
    }
  }
  return rspVal;
}
