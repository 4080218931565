// 是否必填
export function validateNecessary(tipInfo = '请输入', trigger = 'blur', isNeces = true, ) {
  return {
    required: isNeces,
    message: tipInfo,
    trigger: trigger
  }
}
// 验证最大长度
export function validateLen(len = 20) {
  return {
    max: len,
    message: '最大长度为' + len + '个字符',
    trigger: 'blur'
  }
}
// 验证长度，是否等于指定长度
export function validateEqualLen(len) {
  return {
    validator: ((rule, value, callback) => {
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        if (value.length != len) {
          callback(new Error(`只能输入长度为${len}的内容`))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
// 验证内容是否英文数字以及下划线
export function validateChart_() {
  return {
    validator: ((rule, value, callback) => {
      const reg = /^[_a-zA-Z0-9]+$/
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        if (!reg.test(value)) {
          callback(new Error('只能输入英文字母，数字以及下划线'))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
// 验证内容是否包含英文字符
export function validateOnlyChart() {
  return {
    validator: ((rule, value, callback) => {
      const reg = /^[a-zA-Z]+$/
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        if (!reg.test(value)) {
          callback(new Error('只能输入英文字母'))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
/* 是否身份证号码*/
export function validateIDNun() {
  return {
    validator: ((rule, value, callback) => {
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        if ((!reg.test(value)) && value != '') {
          callback(new Error('请输入正确的身份证号码'))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
/* 是否合法IP地址*/
export function validateIP(rule, value, callback) {
  return {
    validator: ((rule, value, callback) => {
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        if ((!reg.test(value)) && value != '') {
          callback(new Error('请输入正确的IP地址'))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
// 是否正整数isInteger
export function validateInt(rule, value, callback) {
  return {
    validator: ((rule, value, callback) => {
      if (value == '' || value == undefined || value == null) {
        callback()
      } else {
        const re = /^[0-9]*[1-9][0-9]*$/
        const rsCheck = re.test(value)
        if (!rsCheck) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
    }),
    trigger: 'blur'
  }
}
