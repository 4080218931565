<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import { connectionSocket } from './utils/websocket'
  export default {
      name: 'app',
      created() {
          connectionSocket()
      }
  }
</script>

<style>
  @import url('assets/scss/index.scss');
  @import url('assets/scss/flex.scss');

  html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  /*  overflow: hidden; */
  }
  body {
  /*   background: url("assets/img/bg.png"); */
  }
  #app{
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
</style>
