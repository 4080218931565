import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

import getters from './getters'
import modelTrain from './modules/modelTrain'

const store = new Vuex.Store({
    modules:{
        modelTrain
    },
    getters
})
export default store