import { Notification, MessageBox } from 'element-ui'

// 成功提示
function successNotify(tip = '操作成功', duration = 3000) {
    Notification({
        title: '提示',
        type: 'success',
        message: tip,
        duration: duration
    })
}

// 失败提示
function warningNotify(tip = '操作异常', duration = 0) {
    Notification({
        title: '提示',
        type: 'warning',
        message: tip,
        duration: duration
    })
}

// 上传校验,formatAry表示接受的格式集,fileSize=-1表示不需要验证文件大小，单位MB
function beforeUpload(file, formatAry = [], fileSize = 0) {
    const fileTypeName = (file.name).substring(file.name.lastIndexOf('.') * 1 + 1 * 1) // 获取后缀名
    const index = formatAry.indexOf(fileTypeName)
    // 建议文件格式
    if (index === -1) {
        Notification ({
            showClose: true,
            type: 'warning',
            message: '上传文件的格式不合符，仅支持'+Object.values(formatAry)+'格式。请重新上传！'
        })
        return false
    }
    // 校验大小
    if (fileSize > 0) {
        const isLtSize = file.size / 1024 / 1024 < Number(fileSize)
        if (!isLtSize) {
            Notification({
                showClose: true,
                type: 'warning',
                message: '上传文件大小不能超过'+ fileSize +'MB'
            })
            return false
        }
    }
}

export default {
    successNotify,
    warningNotify,
    beforeUpload
}