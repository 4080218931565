/* 定义常量的文件 */
export default  {
    modelTypeList: [
        {value: 'cls', label: '物体分类'},
        {value: 'det', label: '目标检测'},
        {value: 'el', label: '其它'}
    ],
    optimizerList: [
        {value: 'Adagrad', label: 'Adagrad'},
        {value: 'Adadelta', label: 'Adadelta'},
        {value: 'RMSprop', label: 'RMSprop'},
        {value: 'Adam', label: 'Adam'}
    ]
}