import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/index',
       //     component: () => import('../components/layout/layout'),
	        component: () => import('../views/home/index'),
            children: [
                {
                    path: '/myModel',
                    name: 'myModel',
                    component: () => import('../views/modelTrain/myModel')
                },
                {
                    path: '/createModel',
                    name: 'createModel',
                    component: () => import('../views/modelTrain/createModel')
                },
				{
				    path: '/index',
				    name: 'index',
					meta: {
					        title: '首页'
					      },
				    component: () => import('../views/home/index')
				},
            ]
        },
		{
			path: '/form',
			name: 'form',
			meta: {
			        title: '提交'
			      },
			component: () => import('../views/form/index')					
		},
		{
			path: '/detail',
			name: 'detail',
			meta: {
			        title: '详情'
			      },
			component: () => import('../views/form/detail')					
		},
		{
			path: '/list',
			name: 'list',
			meta: {
			        title: '详情'
			      },
			component: () => import('../views/form/list')					
		}
    ]
})
export default router