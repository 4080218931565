// socket功能
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import store from '../store/index'

export function connectionSocket() {
    let socket = new SockJS('/bullet')//连接SockJS的endpoint名称为"bullet"
    console.log('socket连接成功');
    // 获取STOMP子协议的客户端对象
    let stompClient = Stomp.over(socket);
    // 定义客户端的认证信息,按需求配置
    let headers = {
        Authorization: ''
    }

    // 拦截输出的一大堆垃圾信息
    stompClient.debug = function () {

    };
    // 向服务器发起websocket连接
    stompClient.connect(headers,() => {
        stompClient.subscribe('/topic/getResponse', rsp => {
            const repObj = JSON.parse(rsp.body);
            store.commit('modelTrain/TRAIN_MUT', repObj)
        }, err => {
            console.log(err)
        })
    })
}